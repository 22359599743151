body {
  font-family: 'Open Sans', sans-serif;
}

.contact-container {
  max-width: 600px;
  margin: 7rem auto;
  padding: 20px;
}

.heading {
  text-align: center;
  margin-bottom: 20px;
}

.intro-text {
  text-align: center;
  margin-bottom: 30px;
}

.contact-form {
  background-color: #f5fdff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-input-group {
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
  padding: 10px 15px;
  margin: 10px 0;
  box-sizing: border-box;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease-in-out;
  outline-color:  #005161;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #005161;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0c687a;
}

.required-marker {
  color: red;
}

.alert-message {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: center;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
}

.loading-spinner {
  margin: 20px auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0c687a;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
