.connect-container {
    font-family: 'Open Sans', sans-serif;
    margin: 7rem auto;
    padding: 1rem;
    background-color: #f5fdff;
    border-radius: 8px;
}

.connect-first-screen {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 1rem 2.5rem;
    min-height: 64vh;
    background-color: #f5fdff;
    width: 100%;
    max-width: 80vw;
}

.connect-text-block {
    flex: 1;
    padding-right: 1rem;
    text-align: centre;
}

.social-media-actions {
    display: flex;
    justify-content: centre;
    align-items: centre;
    flex-direction: column; 
}

.loading-gif {
    width: 200px;
    height: 200px;
    margin-top: 1rem; 
}

.facebook-login-btn {
    padding: 10px 20px; 
    background-color: #1877F2;
    border: none;
    border-radius: 8px; 
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.facebook-login-btn:hover {
    background-color: #165bc0; 
}

.managed-facebook-pages {
    text-align: centre;
    margin-top: 2rem; 
}

.facebook-page-info {
    list-style-type: none;
    margin: 10px 0;
    border-radius: 4px;
    border: 2px solid #ddd;
    background-color: white;
    padding: 10px; 
}

.facebook-page-info button {
    padding: 8px 12px;
    background-color: #005161;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.facebook-page-info button:hover {
    background-color: #00434f;
}

.connect-image-placeholder {
    flex: 1;
    text-align: centre;
}

.hero-image-connect {
    max-width: 70%;
    height: auto;
    display: block;
    margin: 1rem auto;
}

@media only screen and (max-width: 768px) {
    .connect-first-screen {
      flex-direction: column;
    }
  
    .hero-image-connect {
      max-width: 100%;
      height: auto;
    }
}
