/* Root Variables */
:root {
  --navbar-height: 3rem; 
}

/* Navbar Styling */
.navbar {
  background-color: #092F36;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 10rem;
  font-family: 'Open Sans', sans-serif;
  position: fixed; 
  top: 0;       
  left: 0;        
  right: 0;      
  z-index: 100;  
  height: var(--navbar-height);
}

.nav-brand {
  color: white;
  text-decoration: none;
  font-size: 2em;
}

.nav-image {
  width: 4.5rem;
  margin: 0;
}

.nav-brand:hover, .nav-brand:visited {
  color: white;
  text-decoration: none;
}

.nav-menu {
  display: flex;
  align-items: center;
}

.hamburger {
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  display: none;
  cursor: pointer;
  margin-left: auto;
}

.nav-items {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-items li {
  margin-left: 4rem;
  position: relative; /* For submenu positioning */
  height: var(--navbar-height); /* Ensures uniform height for all items */
  display: flex;
  align-items: center; /* Center items vertically */
  
}

.nav-items li a,
.submenu-button {
  display: flex; /* Use flex to align text and arrow */
  align-items: center; /* Center vertically */
  justify-content: space-between; /* Space between text and arrow */
  color: white;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 600;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.nav-items li a:hover,
.submenu-button:hover {
  text-decoration: underline;
}

/* Submenu Items */
.submenu-items {
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #092F36;
  padding: 0;
  margin: 0;
  min-width: 200px;
  display: none; /* Hidden by default */
  flex-direction: column;
  z-index: 1000;
  font-size: small;
}

.submenu-items li {
  margin: 0;
}

.submenu-items li a {
  padding: 0.75rem 1rem;
}


.nav-items li a.signup-button {
  border: 2px solid #ffffff;
  padding: 0.4rem 0.6rem;
  border-radius: 25px;
  color: #003e61;
  background-color: #ffffff;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.signup-button:hover,
.signup-button:focus {
  background-color: transparent;

  color: #ffffff;
}


.submenu:hover .submenu-items {
  display: flex;
}

/* Arrow Rotation on Hover (Desktop Only) */
@media (min-width: 769px) {
  .submenu:hover .submenu-button .arrow {
    transform: rotate(90deg); /* From right to down */
  }
}

/* Expanded Navigation (Mobile) */
.nav-items.expanded {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .nav-items li a.signup-button {
    border: 2px solid #ffffff;
    padding: 0.4rem 0.6rem;
    border-radius: 25px;
    color: #003e61;
    background-color: #ffffff;
    font-weight: 600;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .navbar {
    padding: 0.5rem 3rem;
  }

  .hamburger {
    display: block;
  }

  .nav-items {
    position: absolute; 
    top: var(--navbar-height); 
    right: 0;
    left: 0;
    background-color: #092F36;
    flex-direction: column;
    align-items: flex-start;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }

  .nav-items.expanded {
    max-height: 100vh; /* Allow full height when expanded */
    padding-bottom: 1rem;
  }

  .nav-items li {
    margin: 10px 0;
    width: 100%; 
    padding: 10px 20px;
  }

  /* Submenu Items Styling for Mobile */
  .submenu-items {
    position: static;
    background-color: #0a3d47;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    width: 100%;
    display: flex; /* Ensure flex display on mobile */
    flex-direction: column; /* Ensure vertical layout on mobile */
  }

  .submenu-items.expanded {
    max-height: 500px; /* Arbitrary large value to allow expansion */
  }

  /* Ensure the submenu-button spans full width on mobile */
  .submenu-button {
    width: 100%;
  }

  /* Rotate the arrow when submenu is expanded on mobile */
  .submenu-button.expanded .arrow {
    transform: rotate(90deg); /* From right to down */
  }
}

/* Submenu Button Styling */
.submenu-button {
  display: flex;
  align-items: center;
  justify-content:flex-start;
  width: auto; /* Adjust to fit content on desktop */
}

/* Arrow Styling */
.submenu-button .arrow {
  display: inline-block;
  margin-left: 8px;
  width: 0; 
  height: 0; 
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 7px solid white; /* Points right */
  transition: transform 0.3s ease;
}

.submenu-button.expanded .arrow {
  transform: rotate(90deg); /* Arrow points down when expanded */
}

/* Ensure the submenu-button spans full width on mobile */
@media (max-width: 768px) {
  .submenu-button {
    width: 100%;
  }
}
