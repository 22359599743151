.not-found-container {
    text-align: center;
    padding: 100px 20px;
  }
  
  .not-found-container h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #06525B;
  }
  
  .not-found-container p {
    font-size: 1.5rem;
    margin-bottom: 30px;
    color: #092F36;
  }
  
  .home-link {
    font-size: 1.2rem;
    color: #06525B;
    text-decoration: none;
    border: 2px solid #06525B;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .home-link:hover {
    background-color: #06525B;
    color: #fff;
  }
  