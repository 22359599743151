.expandable-card {
    cursor: pointer;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    margin: 1rem;
    min-height: 18rem;
    max-width: 30rem;
}

.expandable-card.expanded {
    /* Height will automatically adjust to content */
    max-width: 30rem;

}
.expandable-card.expanded .card-details {
    text-align: left;
    font-weight: 600;
    color: #005161;
    cursor: pointer;
    text-decoration: none;
    width: 90%;
}


.card-content {
    position: relative;
    width: 100%;
    background-color: #f5fdff;
    border-radius: 10px;
    overflow: hidden;
}

.card-image {
    height: 30rem; /* Fixed height for the image container */
}

.card-image img {
    width: 100%;
    height: 30rem;
    object-fit: cover;
}

.card-title {
    position: absolute;
    width: 100%;
    top: 1%;
    color: #005161;
}

.card-details {
    font-weight: 600;
    color: #3b5eda;
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
}
@media only screen and (max-width: 768px) {
    .card-title {
        top: 5%;
    }
    .card-image {
        height: 18rem; /* Fixed height for the image container */
    }
    .card-image img {
        height: 18rem;

    }
    .expandable-card {

        max-width: 18rem;
    }
    .expandable-card.expanded {
        /* Height will automatically adjust to content */
        max-width: 18rem;
    
    }
}