.footer {
  background-color: #005f73;
  color: white;
  text-align: center;
  padding: 10px 20px;
}

.footer nav {
  margin-top: 10px;
}

.footer a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  font-size: 16px;
}

.footer a:hover {
  text-decoration: underline;
}


.social-media {
  gap: 15px;
  margin-top: 10px;
}

.social-media a {
  color: #092F36;
  font-size: 1.5em;
}

.social-media a:hover {
  color: #06525B;
}