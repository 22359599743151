.large-container {
  mask: radial-gradient(var(--r1, 100%) var(--r2, 50%) at top, white 79.5%, transparent 80%) top left, radial-gradient(var(--r1, 100%) var(--r2, 50%) at bottom, transparent 79.5%, white 80%) top center, radial-gradient(var(--r1, 100%) var(--r2, 50%) at top, white 79.5%, transparent 80%) top right;
  /* -webkit-mask-size: 33.4% 150%; */
  /* -webkit-mask-repeat: no-repeat; */
  mask-size: 188% 160%;
  mask-repeat: no-repeat;
  background-color: #005161;
  padding: 0 2.5rem;
  min-height: 87vh;
}

.cards {
  display: flex;
  justify-content: space-evenly;
}

.button-home {

  background-color: #005161;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-home:hover,
.button-home:focus {
  background-color: #0c687a;
}


.cardNumber {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  border-radius: 50%;
  background-color: black;
  color: white;
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: 600;
}

.green {
  background-color: #00614a;
}

.brown {
  background-color: #611000;
}

.yellow {
  background-color: #614a00;
}

.blue {
  background-color: #003e61;
}

.cardHeader {
  margin-top: 4rem;
  margin-bottom: 3rem;
  font-style: bold;
  font-weight: 600;
  font-size: 1.5rem;
}

.cardDetails {
  color: #3b5eda;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}

.problem-statement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  /* Adjust the height as necessary */
  color: #f5fdff;
  width: 100%;
  max-width: 80rem;
  margin: auto
}

a:visited,
a:link,
a:active,
a:hover {
  color: inherit;
  background-color: transparent;
}

.first-screen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
  min-height: 87vh;
  /* Adjust the height as necessary */
  background-color: #f5fdff;
  margin: auto;
  width: 100%;
  max-width: 80rem;
}

.first-text-block {
  flex: 1;
  padding-right: 1rem;
  /* Add space between text and image */
}

.second-text-block {
  flex: 1;
  text-align: center;
}

.image-placeholder {
  flex: 1;
  /* Style for the image placeholder */
}

.benefits-image img {
  max-width: 50%;
  object-fit: contain;
  border-radius: 10px;
}

.homepage {
  color: #333;
  font-family: 'Open Sans', sans-serif;
  /* Modern font */
  line-height: 1.6;
  max-width: 2000px;
  /* Sets a maximum width */
  margin: 7rem auto;
  /* Centers the content with auto margins  */
}

.header {
  text-align: center;
  padding: 40px 20px;
}

h1 {
  margin: 0;
  font-size: 4rem;
  font-weight: 600;
}

h2 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 600;
}

h3 {
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
}

.header h1 {
  margin: 0;
  font-size: 4rem !important;
  font-weight: 600;
}

.hero_subhead {
  letter-spacing: -.015rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 125%;
}

.header p {
  font-size: 1.5em;
  margin-top: 10px;
}

.benefits,
.solution {
  margin: auto;
  width: 100%;
  max-width: 90vw;
  padding: 1rem 2.5rem;

}

.hero-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem auto;
  border-radius: 2rem;
}

.benefit-items,
.solution-items {
  display: flex;
  justify-content: space-around;
}

.benefit-item,
.solution-item {
  flex-basis: 21%;
  text-align: center;
  padding: 0.6rem;
  margin: 0.8rem;
  border-radius: 10px;
  perspective: 1000px;
  /* Needed for the flip effect */
}

.benefit-item h3 {
  color: #005f73;
}

.demo-video iframe {
  width: 100%;
  max-width: 560px;
  height: 315px;
  display: block;
  margin: auto;
}

.benefit-card,
.solution-card {
  position: relative;
  width: 100%;
  height: 300px;
  /* adjust this as needed */
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  /* Makes the back of the card invisible when flipped */
  border-radius: 10px;
  overflow: hidden;
  background-color: white;

}



.card-front.green {
  border-bottom-color: #00614a;
  border-bottom-width: 8px;
  border-bottom-style: solid;
}

.card-front.brown {
  border-bottom-color: #611000;
  border-bottom-width: 8px;
  border-bottom-style: solid;
}

.card-front.yellow {
  border-bottom-color: #614a00;
  border-bottom-width: 8px;
  border-bottom-style: solid;
}

.card-front.blue {
  border-bottom-color: 003e61;
  border-bottom-width: 8px;
  border-bottom-style: solid;
}

.card-back {
  transform: rotateY(180deg);
  /* Flips the back card */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #005161;
  

}
/* Flip effect when the .flipped class is added */
.benefit-card.flipped,
.solution-card.flipped {
  transform: rotateY(180deg);
}


/* Hover effect for desktop devices */
@media (min-width: 769px) {
  .benefit-item:hover .benefit-card,
  .solution-item:hover .solution-card {
    transform: rotateY(180deg);
  }
}



.card-back.green {
  border-color: #00614a;
  border-width: 8px;
  border-style: solid;
}

.card-back.brown {
  border-color: #611000;
  border-width: 8px;
  border-style: solid;
}

.card-back.yellow {
  border-color: #614a00;
  border-width: 8px;
  border-style: solid;
}

.card-back.blue {
  border-color: 003e61;
  border-width: 8px;
  border-style: solid;
}

.benefit-item:hover .benefit-card {
  transform: rotateY(180deg);
  /* Flip the card on hover */
}

.solution-item:hover .solution-card {
  transform: rotateY(180deg);
  /* Flip the card on hover */
}


@media (max-width: 768px) {

  .benefit-items,
  .solution-items {
    flex-direction: column;
  }

  .benefit-item,
  .solution-item {
    flex-basis: 100%;
  }

  .large-container {
    mask: radial-gradient(var(--r1, 100%) var(--r2, 50%) at top, white 79.5%, transparent 80%) top left, radial-gradient(var(--r1, 100%) var(--r2, 50%) at bottom, transparent 79.5%, white 80%) top center, radial-gradient(var(--r1, 100%) var(--r2, 50%) at top, white 79.5%, transparent 80%) top right;
    /* -webkit-mask-size: 33.4% 150%; */
    /* -webkit-mask-repeat: no-repeat; */
    mask-size: 475% 165%;
    mask-repeat: no-repeat;
    background-color: #005161;
    padding: 0 2.5rem;
    max-width: 80vw;



  }

  .problem-statement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    min-height: 65vh;
    /* Adjust the height as necessary */
    color: #f5fdff;
    flex-direction: column;
    width: 100%;
    max-width: 80rem;
    margin: auto
  }

  a:visited,
  a:link,
  a:active,
  a:hover {
    color: inherit;
    background-color: transparent;
  }


  .first-screen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2.5rem;
    min-height: 87vh;
    background-color: #f5fdff;
    margin: auto;
    width: 100%;
    max-width: 80vw;
    flex-direction: column;
  }

  .first-text-block,
  .second-text-block {
    flex: 1;
    padding-right: 1rem;
    /* Add space between text and image */
    text-align: center;
    padding-top: 1rem;
  }

  .image-placeholder {
    flex: 1;
    /* Style for the image placeholder */
  }

  .benefits-image img {
    max-width: 70%;
    object-fit: contain;
    border-radius: 10px;
  }

  .homepage {
    color: #333;
    font-family: 'Open Sans', sans-serif;
    /* Modern font */
    line-height: 1.2;
    max-width: 2000px;
    /* Sets a maximum width */
    margin: 7rem auto;
    /* Centers the content with auto margins */
  }

  .header {
    text-align: center;
    padding: 40px 20px;
  }

  h1 {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 600;
  }

  h2 {
    margin: 0;
    font-size: 2rem;
    font-weight: 600;
  }

  h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
  }

  h4 {
    margin: 0.2rem 0 0 0;
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 1rem;
  }

  .header h1 {
    margin: 0;
    font-size: 4rem !important;
    font-weight: 600;
  }

  .hero_subhead {
    letter-spacing: -.015rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 125%;
  }

  .header p {
    font-size: 1.5em;
    margin-top: 10px;
  }

  .benefits,
  .solution {
    margin: auto;
    width: 100%;
    max-width: 80vw;
    padding: 1rem 2.5rem;
    align-items: center;
    text-align: center;

  }

  .hero-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1rem auto;
    border-radius: 2rem;
  }

  .benefit-items,
  .solution-items {
    display: flex;
    justify-content: space-around;
  }

  .benefit-item,
  .solution-item {
    flex-basis: 30%;
    text-align: center;
    padding: 0.6rem;
    margin: 0.2rem;
    border-radius: 10px;
    perspective: 1000px;
    /* Needed for the flip effect */
  }

  .benefit-item h3 {
    color: #005f73;
  }

  .demo-video iframe {
    width: 100%;
    max-width: 560px;
    height: 315px;
    display: block;
    margin: auto;
  }

  .benefit-card,
  .solution-card {
    position: relative;
    width: 100%;
    height: 300px;
    /* adjust this as needed */
    transform-style: preserve-3d;
    transition: transform 0.5s;
  }

  .card-front,
  .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    /* Makes the back of the card invisible when flipped */
    border-radius: 10px;
    overflow: hidden;
  }

  .card-front {
    background-color: white;
  }

  .card-back {
    background-color: white;
    transform: rotateY(180deg);
    /* Flips the back card */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #005161;
  }

  .benefit-item:hover .benefit-card {
    transform: rotateY(180deg);
    /* Flip the card on hover */
  }

  .cards {
    display: flex;
    flex-direction: column;
  }

  .challenges {
    display: flex;
    flex-direction: column;

  }


}