
.hero-image-faq {
  max-width: 70%;
  height: auto;
  display: block;
  margin: 1rem auto;
}

input[type="text"]:focus {
  outline: none;
  border-color: #005161;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.first-screen-faq {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.5rem;
  min-height: 64vh;
  background-color: #f5fdff;
  margin: auto;
  width: 100%;
  max-width: 80vw;
}
.second-screen-faq {
  max-width: 80vw;
  margin: auto;
}

.faq-container {
  font-family: 'Open Sans', sans-serif;
  /* Modern font */
  margin: 7rem auto;
  padding: 1rem;
  background-color: #f5fdff;
  border-radius: 8px;
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
}

.faq-list {
  background: white;
  margin-top: 2rem;
}

.faq-item {
  background: #f5fdff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.faq-list .faq-item h3 {
  color: #333;
}

.faq-list .faq-item p {
  color: #666;
}

.page-numbers {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.page-numbers li {
  margin: 0 5px;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #005161;
  color: white;
  border-radius: 4px;
}
.faq-text-block {
  flex: 1;
  padding-right: 1rem; /* Add space between text and image */
  text-align: center;
}
.second-screen-faq h2 {
  text-align: center;
}
.page-numbers li:hover {
  background-color: #005161;
}

.total-count {
  text-align: center;
  margin-top: 20px;
  font-size: 0.9em;
  color: #333;
}

/* Additional styling as needed */

/* Responsive design considerations */
@media only screen and (max-width: 768px) {
  .faq-container {
    padding: 10px;
  }

  .faq-list .faq-item {
    padding: 10px;
  }
  .hero-image-faq {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1rem auto;
  }

  .first-screen-faq {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2.5rem;
    min-height: 87vh;
    background-color: #f5fdff;
    margin: auto;
    width: 100%;
    max-width: 80vw;
    flex-direction: column;
  }
}

/* Enhancing the pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #005161;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #005161;
}

.pagination button:disabled {
  background-color: #ccc;
}

.pagination .active {
  background-color: #005161;
}



.faq-item {
  cursor: pointer;
  /* Indicate interactivity */
  outline: none;
  /* For accessibility */
}

.faq-item:focus {
  box-shadow: 0 0 5px#005161;
  /* Highlight on focus for keyboard navigation */
}

/* Additional responsive and interactive elements styling as required */