.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 0 0 0;
  background-color: #f5fdff;
  max-width: 100%;
  overflow-x: hidden;
}

body {margin:0;padding:0}

.content {
  flex: 1;
}