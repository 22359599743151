.aboutus-page {
    color: #333;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    max-width: 2000px;
    margin: 7rem auto;
}

/* Hero Section */
.hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5rem;
    min-height: 87vh;
    background-color: #f5fdff;
    margin: auto;
    width: 100%;
    max-width: 80rem;
}

.hero-text-block {
    flex: 1;
    padding-right: 2rem;
    min-width: 300px;
}



.hero-text-block p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.button-aboutus {
    padding: 0.75rem 1.5rem;
    background-color: #005161;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s ease;
}

.button-aboutus:hover,
.button-aboutus:focus {
    background-color: #0c687a;
}

.hero-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px;
}

.hero-image iframe,
.hero-image img {
    max-width: 100%;
    border-radius: 10px;
}

/* Mission and Vision */
.mission-vision {
    mask: radial-gradient(var(--r1, 100%) var(--r2, 50%) at top, white 79.5%, transparent 80%) top left, radial-gradient(var(--r1, 100%) var(--r2, 50%) at bottom, transparent 79.5%, white 80%) top center, radial-gradient(var(--r1, 100%) var(--r2, 50%) at top, white 79.5%, transparent 80%) top right;
    /* -webkit-mask-size: 33.4% 150%; */
    /* -webkit-mask-repeat: no-repeat; */
    mask-size: 188% 160%;
    mask-repeat: no-repeat;
    background-color: #005161;
    padding: 0 2.5rem;
    min-height: 100vh;

}

.mission-vision-div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 80rem;
    margin: auto;
    text-align: center;
}

.mission-vision-core-div {
    color: #f5fdff;
}

.core-card {
    position: relative;
    width: 100%;
    height: 300px;
    /* adjust this as needed */
    transform-style: preserve-3d;
    transition: transform 0.5s;
    color: #005161;
    cursor: pointer; /* Ensures cursor changes to pointer */

}

.cores {
    text-align: center;
    margin: auto;
    width: 100%;
    max-width: 90vw;
    padding: 1rem 2.5rem;

}


.core-card.flipped {
    transform: rotateY(180deg);
}

.core-item:hover .core-card {
    transform: rotateY(180deg);
    /* Flip the card on hover */
}

/* Hover effect for desktop devices */
@media (min-width: 769px) {
    .core-item:hover .core-card {
        transform: rotateY(180deg);
    }
}

/* Core Values */
.core-values {
    padding: 3rem 0;
    background-color: #f5f5f5;
}

.core-div {
    flex: 1 1;
    text-align: center;
}

.core-values h2 {
    text-align: center;
    margin-bottom: 2.5rem;
    font-size: 2rem;
    color: #005161;
}

.values-items {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.value-card {
    flex: 1;
    max-width: 300px;
    margin: 1rem;
    cursor: pointer;
    perspective: 1000px;
}

.value-card .card-front,
.value-card .card-back {
    position: relative;
    width: 100%;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.value-card .card-front {
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.value-card .card-back {
    background-color: #ffffff;
    transform: rotateY(180deg);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.value-card:hover .card-front {
    transform: rotateY(-180deg);
}

.value-card:hover .card-back {
    transform: rotateY(0deg);
}

.values-image img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 1rem;
}

.cardDetails p {
    color: #005161;
    font-weight: bold;
}

/* Call to Action */
.cta-section {
    text-align: center;
    padding: 3rem 0;
}

.cta-section h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #005161;
}

.cta-section p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #555555;
}
.core-item {
    flex-basis: 21%;
    text-align: center;
    padding: 0.6rem;
    margin: 0.8rem;
    border-radius: 10px;
    perspective: 1000px;
}
/* Responsive Styles */
@media (max-width: 768px) {
    .mission-vision-div {
        flex-direction: column;
    }
    .core-item {
        flex-basis: 30%;
        text-align: center;
        padding: 1rem;
        margin: 0.2rem;
        border-radius: 10px;
        perspective: 1000px;
    }

    .hero-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2.5rem;
        min-height: 87vh;
        background-color: #f5fdff;
        margin: auto;
        width: 100%;
        max-width: 80vw;
        flex-direction: column;
    }

    .hero-text-block {
        padding-right: 0;
        margin-bottom: 2rem;
        text-align: center;
    }

    .mission-vision {
        mask: radial-gradient(var(--r1, 100%) var(--r2, 50%) at top, white 79.5%, transparent 80%) top left, radial-gradient(var(--r1, 100%) var(--r2, 50%) at bottom, transparent 79.5%, white 80%) top center, radial-gradient(var(--r1, 100%) var(--r2, 50%) at top, white 79.5%, transparent 80%) top right;
        /* -webkit-mask-size: 33.4% 150%; */
        /* -webkit-mask-repeat: no-repeat; */
        mask-size: 475% 165%;
        mask-repeat: no-repeat;
        background-color: #005161;
        padding: 0 2.5rem;
        max-width: 80vw;
    }



    .team-members,
    .milestone-items,
    .values-items {
        flex-direction: column;
        align-items: center;
    }

    .cores {
        margin: auto;
        width: 100%;
        max-width: 80vw;
        padding: 1rem 0;
        align-items: center;
        text-align: center;

    }

    .team-member,
    .milestone-item,
    .value-card {
        max-width: 90%;
    }

    .core-values .value-card {
        height: auto;
    }

    .value-card .card-front,
    .value-card .card-back {
        height: auto;
    }

    .cta-section p {
        padding: 0 1rem;
    }
}