/* immigrationAIPage.css */



.hero-image-immigration-ai {
  max-width: 70%;
  height: auto;
  display: block;
  margin: 1rem auto;
}

input[type="text"]:focus {
  outline: none;
  border-color: #005161;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.first-screen-immigration-ai {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.5rem;
  min-height: 64vh;
  background-color: #f5fdff;
  margin: auto;
  width: 100%;
  max-width: 80vw;
}

.immigration-ai-container {
  font-family: 'Open Sans', sans-serif;
  margin: 7rem auto;
  padding: 1rem;
  background-color: #f5fdff;
  border-radius: 8px;
}

.ai-text-block {
  flex: 1;
  padding-right: 1rem;
  text-align: center;
}

.second-screen-immigration-ai h2 {
  text-align: center;
}

/* Responsive design considerations */
@media only screen and (max-width: 768px) {
  .immigration-ai-container {
    padding: 10px;
  }

  .immigration-ai-container .feature-item {
    padding: 10px;
  }

  .hero-image-immigration-ai {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1rem auto;
  }

  .first-screen-immigration-ai {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2.5rem;
    min-height: 87vh;
    background-color: #f5fdff;
    margin: auto;
    width: 100%;
    max-width: 80vw;
    flex-direction: column;
  }
}

/* Enhancing the pagination (if pagination is reintroduced) */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #005161;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #005161;
}

.pagination button:disabled {
  background-color: #ccc;
}

.pagination .active {
  background-color: #005161;
}

/* Accessibility and interactivity */
.immigration-ai-container .image-placeholder img {
  cursor: pointer;
  outline: none;
}

.immigration-ai-container .image-placeholder img:focus {
  box-shadow: 0 0 5px #005161;
}
.ai-info-list {
    list-style-type: none;
    padding: 0;
}

.ai-info-list li {
    margin-bottom: 10px;
}

.ai-info-list li a {
    font-weight: bold;
    text-decoration: none;
    color: #1a73e8;
}

.ai-info-list li a:hover {
    text-decoration: underline;
}
